<template>
    <header>
        <div class="banner-so text-bg-ptn-color-gsj-5">
            <div class="container" v-if="!this.sitioPrincipal">
                <p class="small mb-0">
                    Un sitio oficial de Gobierno de San Juan. <a class="text-reset text-decoration-underline" data-bs-toggle="collapse" href="#collapseMasInfo" role="button" aria-expanded="false" aria-controls="collapseExample">
                        Conocé más
                    </a>
                </p>
            </div>
            <div class="container">
                <div class="collapse" id="collapseMasInfo">
                    <div class="row row-cols-1 row-cols-md-2 g-4 py-4">
                        <div class="col">
                            <div class="card text-bg-light">
                                <div class="card-body d-flex align-items-start">
                                    <div class="contenedor-icono d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                        <svg class="ptn-i-casa-de-gob ptn-i-3x text-ptn-color-gsj-2">
                                            <use xlink:href="#ptn-i-casa-de-gob"></use>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 class="card-title h5">Los sitios oficiales tienen dominio sanjuan.gob.ar</h3>
                                        <p class="card-text">Un sitio web con extensión sanjuan.gob.ar es de un organismo oficial del Gobierno de la Provincia de San Juan.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card text-bg-light">
                                <div class="card-body d-flex align-items-start">
                                    <div class="contenedor-icono d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 ">
                                        <svg class="ptn-i-candado ptn-i-3x text-ptn-color-gsj-2">
                                            <use xlink:href="#ptn-i-candado"></use>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 class="card-title h5">Los sitios sanjuan.gob.ar son seguros y usan HTTPS</h3>
                                        <p class="card-text">Un candado o https:// significa que se conectó de manera segura al sitio web sanjuan.gob.ar . Comparta información confidencial sólo en sitios web oficiales y seguros.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- SECCION NAVEGACIÓN-->
        <nav class="navbar navbar-expand-lg navbar-dark text-bg-ptn-color-gsj-brand"
            aria-label="Menu principal">
            <div class="container">
                <a href="https://sanjuan.gob.ar/" class="navbar-brand">
                    <img src="../../assets/img/isologo-gob-sj-negativo.svg" alt="Isologo Gobierno de San Juan" width="151" height="41">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#menu-principal"
                    aria-controls="menu-principal" aria-label="Desplegar menú de navegación">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="offcanvas offcanvas-end text-bg-secondary" tabindex="-1" id="menu-principal"
                    aria-labelledby="menuprincipalLabel">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="menuprincipalLabel">Menú</h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                            aria-label="Cerrar"></button>
                    </div>
                    <div class="offcanvas-body" >
                        <ul
                        v-if="showMenu"
                            class="navbar-nav justify-content-end align-items-md-center text-uppercase flex-grow-1 pe-3">
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="/">Inicio</a>
                            </li>
                            
                            <li  class="nav-item" v-for="(menu,i) in menus" v-bind:key="i" v-bind:class="[menu.submenu ? 'dropdown' : '']">
                                <a class="nav-link" v-bind:class="[menu.submenu ? 'dropdown-toggle' : '']" v-bind:href="menu.url ? menu.url : '#'" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ menu.titulo }}
                                </a>
                                <ul class="dropdown-menu dropdown-menu-dark bg-secondary" v-if="menu.submenu">
                                    <li v-for="(sub, si) in menu.submenu" v-bind:key="si">
                                        <a v-if="!sub.divisor" class="dropdown-item" v-bind:href="sub.url ? sub.url : '#'">{{ sub.titulo }}</a>
                                        <hr v-if="sub.divisor" class="dropdown-divider">
                                    </li>
                                </ul>
                            </li>

                        </ul>
                        <ul
                        v-if="!showMenu"
                            class="navbar-nav justify-content-end align-items-md-center text-uppercase flex-grow-1 pe-3"></ul>
                        <a v-if="mostrarCiudadanoDigital" class="btn text-light" href="https://mi.sanjuan.gob.ar/" target="_blank"><img
                                src="../../assets/img/isologo-ciudadano-digital-negativo.svg" alt="Ciudadano Digital"
                                height="40" width="111"></a>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>


<script>
export default {
    name: 'HeaderMenu',
    props : {
        mostrarCiudadanoDigital : {
            type : Boolean,
            default : true
        },
        menus : Object,
        showMenu : {
            type : Boolean,
            default : true
        },
        sitioPrincipal : {
            type : Boolean,
            default : false
        }

        
    }
}
</script>